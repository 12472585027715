<template>
  <b-card class="mx-auto mt-2">
    <div class="d-flex w-100">
        <h4 class="mx-auto">RIASEC Test Results</h4>
    </div>
    
    <b-button class="" variant="primary" @click="$router.go(-1)">
      Go Back
    </b-button>
    <div class="mx-auto mt-2">
      <vue-good-table
        mode="remote"
        :pagination-options="{
          enabled: true,
          perPage: serverParams.perPage,
          perPageDropdown: [10, 20, 50, 100],
          dropdownAllowAll: false,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :is-loading.sync="isLoading"
        :total-rows="totalRecords"
        :rows="results"
        :columns="columnDefs"
        style-class="vgt-table condensed bordered w-100"
        compact-mode
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-selected-rows-change="onSelectionChange"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'interest_code'">
            <b-badge
              v-for="label in props.row.interest_code"
              :key="label.key"
              class="h-6 mx-1"
              variant="warning"
            >
              {{ label }}
            </b-badge>
          </span>

          <span v-else-if="props.column.field === 'test_id'">
            <div class="d-flex">
              <h6 class="mt-1">{{ props.row.test_id }}</h6>
              <b-button
                v-b-tooltip.hover.top="`Click here to copy test link.`"
                class="btn-icon grid-btn ml-1"
                variant="outline-primary"
                @click="copyTestLink(props.row.test_id)"
              >
                <feather-icon
                  icon="CopyIcon"
                  svg-classes="hover:text-primary stroke-current"
                />
              </b-button>
            </div>
          </span>

          <span v-else-if="props.column.field === 'actions'">
            <b-button
              v-b-tooltip.hover.top="`Click here to view more information.`"
              class="btn-icon grid-btn ml-1"
              variant="outline-primary"
              @click="viewMore(props.row.tag)"
            >
              <feather-icon
                icon="EyeIcon"
                svg-classes="hover:text-primary stroke-current"
              />
            </b-button>

            <b-button
              v-if="props.row.tag === 'riasec'"
              v-b-tooltip.hover.top="`Click here to send riasec questions.`"
              class="btn-icon grid-btn mx-1"
              variant="outline-primary"
              @click="performAction(props.row.tag)"
            >
              <feather-icon
                icon="SendIcon"
                svg-classes="hover:text-primary stroke-current"
              />
            </b-button>
          </span>
        </template>
      </vue-good-table>
    </div>
  </b-card>
</template>

<script>
import { debounce } from "lodash";
import Ripple from "vue-ripple-directive";
import { completionMixins } from "@/components/mixins/CompletionMixins";
import { VueGoodTable } from "vue-good-table";
import CellRendererImage from "../EventManager/cell-renderer/CellRendererImage.vue";
import CellRendererCategory from "../EventManager/cell-renderer/CellRendererCategory.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    VueGoodTable,
    CellRendererImage,
    CellRendererCategory,
  },

  mixins: [completionMixins],
  data() {
    return {
      isLoading: false,
      isUserLoading: false,
      searchQuery: "",
      userCount: 0,
      totalRecords: 0,
      results: [],
      selectedUsers: [],
      selectAllUsers: "",

      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          label: "Fullname",
          field: "fullname",
          filterOptions: {
            trigger: "enter",
            enabled: true,
            placeholder: "",
          },
          // pinned: true,
        },
        {
          label: "Test ID",
          field: "test_id",
          filterOptions: {
            trigger: "enter",
            enabled: true,
            placeholder: "",
          },
        },

        {
          label: "Interest Code",
          field: "interest_code",
          filterOptions: {
            trigger: "enter",
            enabled: true,
            placeholder: "",
          },
        },

        {
          label: "Done On",
          field: "done_on",
          filterOptions: {
            trigger: "enter",
            enabled: true,
            placeholder: "",
          },
        },
        {
          label: "Test Created On",
          field: "created_on",
          filterOptions: {
            trigger: "enter",
            enabled: true,
            placeholder: "",
          },
        },
      ],
      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 10,
      },
      activateRiasec: false,
      exportingCSVIndex: null,
      exportingCSV: false,
      selectedRows: null,
      mqShallShowLeftSidebar: false,
      allUsers: [],
    };
  },
  async created() {},
  mounted() {
    this.getRecords();
  },
  methods: {
    getRecords() {
      const query = `?params=${JSON.stringify(this.serverParams)}`;
      this.$http
        .get(`/api/all-riasec-results${query}`)
        .then((response) => {
          const { success } = response.data;
          if (success) {
            this.results = response.data.all_riasec_results;
            this.totalRecords = response.data.total_records;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    copyTestLink(test_id) {
      this.copyTextToClipboard(
        `${window.location.origin}/events-manager/interview-preparation/riasec/${test_id}`
      );
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: "Success",
            icon: "AlertCircleIcon",
            text: "Riasec Test link copied successfully",
            variant: "success",
          },
        },
        { timeout: this.$longestTimeout }
      );
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        () => {},
        (err) => {}
      );
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
      this.getRecords();
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
    },

    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field;
        const sortDetails = {};
        sortDetails[fieldName] = params[0].type;
        this.updateParams({ sort: sortDetails });
      }
    },

    onColumnFilter(params) {
      this.updateParams(params);
    },
    onSelectionChange(params) {
      this.selectedRows = params.selectedRows;
    },
    updateFilters(column, value) {
      const currentFilters = this.serverParams.columnFilters;
      currentFilters[column] = value;
      this.updateParams({ columnFilters: currentFilters });
    },
  },
};
</script>
